import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Docker',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/383e60ac-c81d-481c-a568-9c249f879e00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#1d63ed',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
